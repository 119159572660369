import {createAction} from '@reduxjs/toolkit'

const initialState = {
    list: [],
    object: {},
    miscData: {},
    isRequestCompleted: false,
    isLoading: false,
    isDetailLoading: false,
    isEdit: false,
    isError: false,
    isSuccess: false
}

export const setMenus = createAction("menuReducer/setMenus")
export const setMenu = createAction("menuReducer/setMenu")
export const setLoading = createAction("menuReducer/setLoading")
export const editMenu = createAction("menuReducer/editMenu")
export const setDetailLoading = createAction("menuReducer/setDetailLoading")
export const setIsEdit = createAction("menuReducer/setIsEdit")
export const setIsMenuError = createAction("menuReducer/setIsMenuError")
export const setIsMenuSuccess = createAction("menuReducer/setIsMenuSuccess")
export const setRequestCompleted = createAction("menuReducer/setRequestCompleted")

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case setMenus.type:
            return {
                ...state,
                list: action.payload.data,
                miscData: action.payload.misc,
                isLoading: false,
                isDetailLoading: false,
                isEdit: false,
                isError: false,
                isSuccess: false
            }
        case setMenu.type:
            return {
                ...state,
                object: action.payload.data,
                isEdit: false,
                isLoading: false,
                isDetailLoading: false
            }
        case setLoading.type:
            return {
                ...state,
                isLoading: action.payload
            }
        case setDetailLoading.type:
            return {
                ...state,
                isDetailLoading: action.payload
            }
        case editMenu.type:
            const data = action.payload.data
            console.log('editDataGet', data)
            return {
                ...state,
                object: {...data,
                    category: data.category === "Highlight" ? "Highlight" :  data.category === "Menu view" ? "Menu view" : data.category === "Appetizer" ? "Appetizer" : data.category === "Buffet" ? "Buffet" : '',
                    restaurantId: {label: data.restaurant.name, value: data.restaurant.id},
                    menuTypeId: data.id,
                    attachmentId: data.restaurantPhoto.attachmentId,
                    type: 1,
                    referenceType: 2
                },
                isEdit: true
            }
        case setRequestCompleted.type:
            return {
                ...state,
                isRequestCompleted: action.payload
            }
        case setIsEdit.type:
            return {
                ...state,
                isEdit: action.payload.data
            }
        case setIsMenuError.type:
            return {
                ...state,
                isError: action.payload
            }
        case setIsMenuSuccess.type:
            console.log('action.payload', action.payload)
            return {
                ...state,
                isSuccess: action.payload
            }
        default:
            return state
    }
}

export default menuReducer