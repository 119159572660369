import {createAction} from '@reduxjs/toolkit'

const initialState = {
    list: [],
    object: {},
    miscData: {},
    isRequestCompleted: false,
    isLoading: false,
    isDetailLoading: false,
    isEdit: false,
    isError: false,
    isSuccess: false
}

export const setDeals = createAction("dealReducer/setDeals")
export const setDeal = createAction("dealReducer/setDeal")
export const setLoading = createAction("dealReducer/setLoading")
export const editDeal = createAction("dealReducer/editDeal")
export const setDetailLoading = createAction("dealReducer/setDetailLoading")
export const setIsEdit = createAction("dealReducer/setIsEdit")
export const setIsDealError = createAction("dealReducer/setIsDealError")
export const setIsDealSuccess = createAction("dealReducer/setIsDealSuccess")
export const setRequestCompleted = createAction("dealReducer/setRequestCompleted")

const dealReducer = (state = initialState, action) => {
    switch (action.type) {
        case setDeals.type:
            return {
                ...state,
                list: action.payload.data,
                miscData: action.payload.misc,
                isLoading: false,
                isDetailLoading: false,
                isEdit: false,
                isError: false,
                isSuccess: false
            }
        case setDeal.type:
            return {
                ...state,
                object: action.payload.data,
                isEdit: false,
                isLoading: false,
                isDetailLoading: false
            }
        case setLoading.type:
            return {
                ...state,
                isLoading: action.payload
            }
        case setDetailLoading.type:
            return {
                ...state,
                isDetailLoading: action.payload
            }
        case editDeal.type:
            const data = action.payload.data
            console.log("onEditGet", data)

            return {
                ...state,
                object: {...data,
                    attachmentId: data.restaurantPhoto.attachmentId,
                    type: 2,
                    referenceType: 2,
                    menuKeyword: data.dealKeyword,
                    menuTypeId: data.id,
                    startDate: new Date(data.startDate),
                    endDate: new Date(data.endDate)
                },
                isEdit: true
            }
        case setRequestCompleted.type:
            return {
                ...state,
                isRequestCompleted: action.payload
            }
        case setIsEdit.type:
            return {
                ...state,
                isEdit: action.payload.data
            }
        case setIsDealError.type:
            return {
                ...state,
                isError: action.payload
            }
        case setIsDealSuccess.type:
            return {
                ...state,
                isSuccess: action.payload
            }
        default:
            return state
    }
}

export default dealReducer