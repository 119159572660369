import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import {store} from "../../../redux/store"
import {unAuthorize} from "../../../redux/auth/actions"

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
        config => {
          // ** Get token from localStorage
          const accessToken = this.getToken()

          // ** If token is present add it to request's Authorization Header
          if (accessToken) {
            // ** eslint-disable-next-line no-param-reassign
            const token = accessToken.replaceAll('"', "")
            config.headers.Authorization = `${this.jwtConfig.tokenType} ${token}`
          }
          return config
        },
        error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
        response => response,
        error => {
          const { response } = error

          // ** if (status === 401) {
          if (response && response.status === 401) {
            store.dispatch(unAuthorize())
          }
          return Promise.reject(error)
        }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }
}
