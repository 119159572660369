import {createAction} from '@reduxjs/toolkit'

const initialState = {
    list: [],
    object: {},
    miscData: {},
    isRequestCompleted: false,
    isLoading: false,
    isDetailLoading: false,
    isEdit: false,
    isError: false,
    isSuccess: false
}

export const setDiscounts = createAction("discountReducer/setDiscounts")
export const setDiscount = createAction("discountReducer/setDiscount")
export const setLoading = createAction("discountReducer/setLoading")
export const editDiscount = createAction("discountReducer/editDiscount")
export const setDetailLoading = createAction("discountReducer/setDetailLoading")
export const setIsEdit = createAction("discountReducer/setIsEdit")
export const setIsDiscountError = createAction("discountReducer/setIsDiscountError")
export const setIsDiscountSuccess = createAction("discountReducer/setIsDiscountSuccess")
export const setRequestCompleted = createAction("discountReducer/setRequestCompleted")

const discountReducer = (state = initialState, action) => {
    switch (action.type) {
        case setDiscounts.type:
            return {
                ...state,
                list: action.payload.data,
                miscData: action.payload.misc,
                isLoading: false,
                isDetailLoading: false,
                isEdit: false,
                isError: false,
                isSuccess: false
            }
        case setDiscount.type:
            return {
                ...state,
                object: action.payload.data,
                isEdit: false,
                isLoading: false,
                isDetailLoading: false
            }
        case setLoading.type:
            return {
                ...state,
                isLoading: action.payload
            }
        case setDetailLoading.type:
            return {
                ...state,
                isDetailLoading: action.payload
            }
        case editDiscount.type:
            const data = action.payload.data
            console.log('datqqq', data)
            return {
                ...state,
                object: {
                    ...data,
                    restaurantId: data.restaurant.id,
                    photoReferenceType: 2,
                    attachmentId: data.restaurantPhoto.attachmentId,
                    referenceType: data.restaurantPhoto.referenceType,
                    reference: data.restaurantPhoto.reference,
                    cardDiscounts: data.cardDiscount?.map(i => {
                            return  {label: i.card.name, value: i.card.id}
                        }
                    ),
                    discountType: {label: data.discountType === 1 ? 'Online' :  data.discountType === 2 ? 'Card' : data.discountType === 3 ? 'ESR' : null, value: data.discountType === 1 ? 1 :  data.discountType === 2 ? 2 : data.discountType === 3 ? 3 : null},
                    link: data.link
                },
                isEdit: true
            }
        case setRequestCompleted.type:
            return {
                ...state,
                isRequestCompleted: action.payload
            }
        case setIsEdit.type:
            return {
                ...state,
                isEdit: action.payload.data
            }
        case setIsDiscountError.type:
            return {
                ...state,
                isError: action.payload
            }
        case setIsDiscountSuccess.type:
            return {
                ...state,
                isSuccess: action.payload
            }
        default:
            return state
    }
}

export default discountReducer