// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './auth/authentication'
import restaurant from "./restaurant/reducer"
import bank from "./bank/reducer"
import card from "./card/reducer"
import resDeal from "./deal/reducer"
import resMenu from "./menu/reducer"
import resBuffet from "./buffet/reducer"
import resDiscount from "./discount/reducer"
import complain from "./complain/reducer"

const rootReducer = {
  auth,
  navbar,
  layout,
  restaurant,
  bank,
  card,
  resDeal,
  resMenu,
  resBuffet,
  resDiscount,
  complain
}

export default rootReducer
