import {createAction} from '@reduxjs/toolkit'

const initialState = {
    list: [],
    object: {},
    miscData: {},
    isRequestCompleted: false,
    isLoading: false,
    isDetailLoading: false,
    isEdit: false,
    isError: false,
    isSuccess: false
}

export const setComplains = createAction("complainReducer/setComplains")
export const setComplain = createAction("complainReducer/setComplain")
export const setLoading = createAction("complainReducer/setLoading")
export const editComplain = createAction("complainReducer/editComplain")
export const setDetailLoading = createAction("complainReducer/DetailLoading")
export const setIsEdit = createAction("complainReducer/setIsEdit")
export const setIsComplainError = createAction("complainReducer/setIsReviewError")
export const setIsComplainSuccess = createAction("complainReducer/setIsReviewSuccess")
export const setRequestCompleted = createAction("complainReducer/setRequestCompleted")

const complainReducer = (state = initialState, action) => {
    switch (action.type) {
        case setComplains.type:
            return {
                ...state,
                list: action.payload.data,
                miscData: action.payload.misc,
                isLoading: false,
                isDetailLoading: false,
                isEdit: false,
                isError: false,
                isSuccess: false
            }
        case setComplain.type:
            return {
                ...state,
                object: action.payload.data,
                isEdit: false,
                isLoading: false,
                isDetailLoading: false
            }
        case setLoading.type:
            return {
                ...state,
                isLoading: action.payload
            }
        case setDetailLoading.type:
            return {
                ...state,
                isDetailLoading: action.payload
            }
        case editComplain.type:
            const data = action.payload.data
            console.log('editComplain', data)
            return {
                ...state,
                object: {...data, restaurantId: {label: data.restaurant.name, value: data.restaurant.id}},
                isEdit: true
            }
        case setRequestCompleted.type:
            return {
                ...state,
                isRequestCompleted: action.payload
            }
        case setIsEdit.type:
            return {
                ...state,
                isEdit: action.payload.data
            }
        case setIsComplainError.type:
            return {
                ...state,
                isError: action.payload
            }
        case setIsComplainSuccess.type:
            return {
                ...state,
                isSuccess: action.payload
            }
        default:
            return state
    }
}

export default complainReducer
