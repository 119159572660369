import {createAction} from '@reduxjs/toolkit'

const initialState = {
    list: [],
    object: {},
    miscData: {},
    isRequestCompleted: false,
    isLoading: false,
    isDetailLoading: false,
    isEdit: false,
    isError: false,
    isSuccess: false
}

export const setCards = createAction("cardReducer/setCards")
export const setCard = createAction("cardReducer/setCard")
export const setLoading = createAction("cardReducer/setLoading")
export const editCard = createAction("cardReducer/editCard")
export const setDetailLoading = createAction("cardReducer/setDetailLoading")
export const setIsEdit = createAction("cardReducer/setIsEdit")
export const setIsCardError = createAction("cardReducer/setIsCardError")
export const setIsCardSuccess = createAction("cardReducer/setIsCardSuccess")
export const setRequestCompleted = createAction("cardReducer/setRequestCompleted")

const cardReducer = (state = initialState, action) => {
    switch (action.type) {
        case setCards.type:
            return {
                ...state,
                list: action.payload.data,
                miscData: action.payload.misc,
                isLoading: false,
                isDetailLoading: false,
                isEdit: false,
                isError: false,
                isSuccess: false
            }
        case setCard.type:
            return {
                ...state,
                object: action.payload.data,
                isEdit: false,
                isLoading: false,
                isDetailLoading: false
            }
        case setLoading.type:
            return {
                ...state,
                isLoading: action.payload
            }
        case setDetailLoading.type:
            return {
                ...state,
                isDetailLoading: action.payload
            }
        case editCard.type:
            const {data} = action.payload
            delete data.restaurantPhoto.attachmentId
            console.log(data, "data")
            return {
                ...state,
                object: {...data,
                    //rating: {value: data.rating, label: data.rating},
                    bankId: {label: data.bankDtoRes?.name, value: data.bankDtoRes?.id},
                    cardType: {label: data.cardType === 1 ? "Credit" : "Debit", value: data.cardType === 1 ? 1 : 2},
                    providerType: {label: data.providerType === 1 ? "Visa" : "Master", value: data.providerType === 1 ? 1 : 2},
                    attachmentId: data.restaurantPhoto.attachmentId,
                    referenceType: data.restaurantPhoto.referenceType,
                    reference: data.restaurantPhoto.reference,
                    photoReferenceType: 2
                },
                isEdit: true
            }
        case setRequestCompleted.type:
            return {
                ...state,
                isRequestCompleted: action.payload
            }
        case setIsEdit.type:
            return {
                ...state,
                isEdit: action.payload.data
            }
        case setIsCardError.type:
            return {
                ...state,
                isError: action.payload
            }
        case setIsCardSuccess.type:
            return {
                ...state,
                isSuccess: action.payload
            }
        default:
            return state
    }
}

export default cardReducer
